import { Component, Input } from '@angular/core';
import {
  NgxSkeletonLoaderConfig,
  NgxSkeletonLoaderModule,
} from 'ngx-skeleton-loader';

const DEFAULT_THEME = { margin: '0', width: '100%' };

@Component({
  selector: 'app-skeleton',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.scss',
})
export class SkeletonComponent {
  @Input() count: number = 1;
  @Input() appearance: NgxSkeletonLoaderConfig['appearance'] = 'line';
  @Input() animation: NgxSkeletonLoaderConfig['animation'] = 'progress';
  @Input() theme: NgxSkeletonLoaderConfig['theme'] = DEFAULT_THEME;
  @Input() loadingText: NgxSkeletonLoaderConfig['loadingText'] = '';
}
